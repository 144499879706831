[]<template>
  <perfect-scrollbar :style="{ height: `${containerHeight - 100}px` }">
    <transition name="slide-top-fadeout" mode="out-in">
      <div
        class="describe-vue d-flex flex-column justify-content-center align-items-center mt-3"
        v-if="!verifyFiles && !formTextOperation"
      >
        <uello-icon class="icon-color--warn" icon="information" size="large" />
        <h4 class="text-dark-darken my-3">{{ $t('pages.request.descTitle') }}</h4>
        <p class="text-dark-darken mb-3">{{ $t('pages.request.descSubTitle') }}</p>

        <p class="m-0 text-dark-darken" v-html="$t('pages.request.personalData')"></p>
        <p class="m-0 text-dark-darken" v-html="$t('pages.request.address')"></p>
        <p class="m-0 text-dark-darken" v-html="$t('pages.request.extras')"></p>

        <transition name="slide-top-fadeout" mode="out-in">
          <div
            v-if="selectedFileType === '' && !verifyFiles"
            class="d-flex justify-content-between align-items-center w-100 px-3 pt-5"
          >
            <request-item
              v-for="item in options"
              :key="item.type"
              :item="item"
              @selected-type="setSelectedFileType"
            />
          </div>
          <u-pickup v-if="selectedFileType === 'pickup' && !verifyFiles" :type="selectedFileType" />
          <u-files-up
            v-if="selectedFileType !== 'pickup' && !verifyFiles"
            :type="selectedFileType"
            @verifyFiles="setVerifyFiles"
            @form-textbox-ready="setFormTextbox"
            @filesup="setItems"
            @operation="setOperation"
          />
        </transition>
      </div>
      <list-items
        v-if="verifyFiles && operations && !formTextReady"
        :items="items"
        :operations="operations"
      />
      <form-textbox
        v-if="showForm && formTextReady && formTextOperation"
        :operationType="formTextOperation"
        :operation="operations"
        :form-textbox="true"
        @cancel-solicitation="$emit('cancel-solicitation')"
      ></form-textbox>
    </transition>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';

import { centralWindowSize } from '@/mixins';
import { haveStop, getOperationType, safeStorage } from '@/utils';
import * as types from '@/store/types';
import { UelloIcon } from '@uello/componentello';

import FormTextbox from '../u-spot-route/SpotMap.vue';

export default {
  name: 'describe-page',
  mixins: [centralWindowSize],
  props: {
    selectedFileType: {
      type: String,
      default: () => '',
    },
    verifyFiles: {
      type: Boolean,
      required: true,
    },
    showForm: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    RequestItem: () => import('./RequestItem.vue'),
    UFilesUp: () => import('./UFilesUp.vue'),
    ListItems: () => import('./ListItems.vue'),
    UPickup: () => import('./UPickup.vue'),
    // FormTextbox: () => import('../u-spot-route/SpotMap.vue'),
    FormTextbox,
    UelloIcon,
  },
  data() {
    return {
      options: [
        {
          type: 'xml',
          text: this.$t('requestItems.xml'),
          class: 'text-info',
          help: process.env.VUE_APP_MOVIDESK_HELP_XML,
        },
        {
          type: 'csv',
          text: this.$t('requestItems.csv'),
          class: 'text-success',
          help: process.env.VUE_APP_MOVIDESK_HELP_CSV,
        },
        {
          type: 'form-textbox',
          text: this.$t('requestItems.form'),
          class: 'text-primary',
          help: process.env.VUE_APP_MOVIDESK_HELP_FORM,
        },
        {
          type: 'pickup',
          text: this.$t('requestItems.pickup'),
          class: 'text-primary',
          help: process.env.VUE_APP_MOVIDESK_HELP_PICKUP,
        },
      ],
      file: null,
      items: {
        success: [],
        failed: [],
      },
      operations: null,
      formTextOperation: null,
      formTextReady: false,
    };
  },
  created() {
    if (haveStop()) {
      this.options = [
        ...this.options,
        {
          type: 'spot',
          text: this.$t('requestItems.spot'),
          class: 'text-primary',
        },
      ];
    }
  },
  destroyed() {
    this.formTextReady = false;
  },
  watch: {
    selectedFileType(value) {
      if (!value) {
        this.selected = null;
        this.operations = null;
      }
    },
    showForm(value) {
      if (!value) {
        this.operations = null;
        this.selected = null;
        this.formTextOperation = null;
        this.formTextReady = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      getChilds: types.GETTER_PARTNER_CHILDS,
    }),
  },
  methods: {
    setSelectedFileType(fileType) {
      this.$emit('selected', fileType);
    },
    setVerifyFiles() {
      this.$emit('verify');
    },
    setItems(items) {
      this.items = items;

      if (!this.operations) {
        const { operations, document } = safeStorage.getItem('config');

        this.operations = {
          cnpj: document,
          operation: operations.filter(op => op.type !== 'spot'),
        };
      }
    },
    setOperation(op) {
      this.operations = op;

      if (Array.isArray(op.operation)) {
        return;
      }

      this.$store.commit(`@orders/MUTATE_SET_CHOSEN_OPERATIONS`, op.operation);
    },
    setFormTextbox(operation) {
      const { operations, document: cnpj, has_childs } = safeStorage.getItem('config');

      if (!this.operations) {
        this.operations = {
          operation: operations[0].id,
          cnpj,
        };
      }
      /* eslint-disable-next-line */
      this.operations['is_partner_child'] = has_childs;
      this.formTextOperation = getOperationType(
        operation && operation.text ? this.operations : operation
      );

      if (!this.formTextOperation && !has_childs) {
        this.formTextOperation = operations.find(
          op => Number(op.id) === Number(operation.value || operation.operation)
        ).type;
      }

      this.formTextReady = true;
      this.$emit('show-form');
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 100px;
.scroll-container-file {
  max-height: calc(100vh - 100px) !important;
}
.icon-color {
  &--warn {
    color: var(--warning) !important;
  }
}
</style>
